<template>
    <div>
        <BT-Fast-Start-Banner />
        <BT-Blade-Item
            bladeName="location"
            navigation="locations"
            title="Location"
            :bladesData="bladesData">
            <template v-slot="{ item, data, isChanged }">

                <BT-Field-String
                    v-model="item.locationName"
                    label="Location Name"
                    :isEditing="data.isEditing || data.isNew" />
                    
                <BT-Field-String
                    v-model="item.addressLineOne"
                    label="Address Line One"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-String
                    v-model="item.streetNumber"
                    label="Street Number"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-String
                    v-model="item.streetName"
                    label="Street Name"
                    :isEditing="data.isEditing || data.isNew" />
                
                <BT-Field-String
                    v-model="item.suburb"
                    label="Suburb"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-String
                    v-model="item.state"
                    label="State"
                    :isEditing="data.isEditing || data.isNew" />
                
                <BT-Field-String
                    v-model="item.postcode"
                    label="Postcode"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model="item.lat"
                    label="Latitude"
                    :isEditing="data.isEditing || data.isNew" />

                <BT-Field-Number
                    v-model="item.lng"
                    label="Longitude"
                    :isEditing="data.isEditing || data.isNew" />

                <v-alert v-if="isChanged" class="warning">
                    If you change the address, please be sure to update the geo-coordinates
                </v-alert>
                
                <BT-Blade-Button
                    v-if="data.isEditing || data.isNew"
                    :block="false"
                    @click="loadCoordinates(item)"
                    icon="mdi-refresh"
                    label="Load Coordinates" />

                <v-divider class="my-1" />

                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="stock-levels"
                    :id="item.id"
                    label="Stock Levels" />

                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="stock-items"
                    :id="item.id"
                    label="Stocked Items" />

                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="thresholds"
                    :id="item.id"
                    label="Stock Thresholds" />

                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="releases"
                    :id="item.id"
                    label="Releases" />
                    
                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="receivals"
                    :id="item.id"
                    label="Receivals" />
                    
                <BT-Blade-Button
                    v-if="isStockHub"
                    :bladesData="bladesData"
                    bladeName="batches"
                    :id="item.id"
                    label="Batches" />
    <!-- 
                <v-divider class="my-2" />
                -->
                <BT-Blade-Button
                    v-if="!data.isNew && (isStockHub || isSupplierSettingsHub)"
                    @click="data.openBlade({ bladeName: 'supply-pointer', data: { id: 'new', location: item } })"
                    label="Create Supply Coverage" />

                <BT-Snack v-model="msg" />

                <v-expansion-panels v-model="panelV">
                    <BT-Blade-Expansion-Item
                        navigation="delivery-locations"
                        :itemID="item.id"
                        title="Delivery Information">
                        <template v-slot="{ item, data }">
                            <BT-Field-String
                                label="Delivery Instructions"
                                v-model="item.driverInstructions"
                                :isEditing="data.isEditing" />
                        </template>
                    </BT-Blade-Expansion-Item>
                    <BT-Expansion-Panel-List
                        v-if="!data.isNew && (isStockHub || isSupplierSettingsHub)"
                        navigation="supply-pointers"
                        label="Supply Coverage"
                        :canRefresh="true"
                        :params="{ locationID: item.id }"
                        @change="pointer => { data.openBlade({ bladeName: 'supply-pointer', data: { id: pointer.id, location: item } }) }">
                        <template v-slot="{ item }">
                            <v-list-item-icon>
                                <v-icon>mdi-map-search</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-subtitle>{{ item.weekdays }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                    </BT-Expansion-Panel-List>
                    <v-expansion-panel>
                        <v-expansion-panel-header>Coordinates</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <GmapMap
                                ref="locMap"
                                :center="centerPosition"
                                :zoom="7"
                                    style="width: 100%; height: 250px;"
                                    :options="{
                                        zoomControl: true,
                                        mapTypeControl: false,
                                        scaleControl: false,
                                        streetViewControl: false,
                                        rotateControl: false,
                                        fullscreenControl: false,
                                        disableDefaultUi: false }">                    
                                        <gmap-marker v-if="item != null" :position="{ lat: item.lat, lng: item.lng }" />
                            </GmapMap> 
                            <BT-Select-Dialog
                                :hideButton="true"
                                :items="possibleAddresses"
                                :returnObject="true"
                                listItemText="formatted_address"
                                v-model="selectAddresses" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

            </template>
        </BT-Blade-Item>
    </div>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue';
import { getLocationLine } from '~helpers';

export default {
    name: 'Location-Blade',
    components: {
        BTFastStartBanner: () => import('~components/BT-Fast-Start-Banner.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue')
    },
    data: function() {
        return {
            panelV: null,
            possibleAddresses: [],
            selectAddresses: false,
            msg: null
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        isStockHub: {
            type: Boolean,
            default: false
        },
        isSupplierSettingsHub: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        centerPosition() {
           return this.position != null ? this.position : { lat: -38, lng: 144 };
        },
        google: getGoogleMapsAPI
    },
    methods: {
        selectAddress(item, address) {      
            item.lat = address.geometry.location.lat(); 
            item.lng = address.geometry.location.lng();
            this.isChanged = false;
            this.$forceUpdate();
        }, 
        loadCoordinates(item) {
            var add = getLocationLine(item, true);

            var request = {
                address: add
            };

            console.log(request);

            var self = this;

            new this.google.maps.Geocoder().geocode(request, (results, status) => {
                if (status === 'OK') {
                    //proceed
                    console.log('results');
                    console.log(results);
                    if (results.length > 1) {
                        self.possibleAddresses = results;
                        self.selectAddresses = true;
                        self.msg = results.length;
                    } 
                    else if (results.length == 1) {
                        item.lat = results[0].geometry.location.lat();
                        item.lng = results[0].geometry.location.lng();
                        self.$forceUpdate();
                    } 
                    else {
                        self.msg = 'Address Not Found';
                        self.isChanged = true;
                    }
                }
            })
        },
    }
}
</script>